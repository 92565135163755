<template>
  <ValidationProvider :name="$attrs.label" :rules="rules" v-slot="{ errors, valid }">
    <v-text-field
        v-model="innerValue"
        v-bind="$attrs"
        v-on="$listeners"
        :error-messages="errors.concat($attrs.errors || [])"
        :label="$t(`label.${$attrs.label}`)"
        :success="innerValue && innerValue.length > 0 && valid"
    />
  </ValidationProvider>
</template>

<script>
import {ValidationProvider} from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: null
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
