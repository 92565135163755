<template>
  <v-card rounded="lg" min-height="50vh">
    <GmapMap
        :center="maps.position"
        :zoom="maps.zoom"
        map-type-id="terrain"
        style="width: 100%; height: 300px"
    >
      <GmapMarker :position="maps.position"/>
    </GmapMap>

      <ValidationObserver ref="ContactForm" v-slot="{ validate, handleSubmit, invalid, validated }">
        <v-form ref="ContactForm" v-model="form.valid">
            <v-card-text>
              <div v-if="form.messages">
                <v-sheet v-for="error in form.messages" :key="error && error._id">
                  <v-alert type="error" dismissible close-icon="mdi-close-thick">
                    {{ error }}
                  </v-alert>
                </v-sheet>
              </div>
              <v-alert v-if="form.success" type="success" dismissible close-icon="mdi-close-thick" v-text="form.success"/>
              <TextInput
                  required
                  label="name"
                  prepend-icon="mdi-account"
                  rules="required"
                  v-model="form.fields.name"
                  :errors="form.errors.name"
              />
              <TextInput
                  required
                  label="email"
                  prepend-icon="mdi-email"
                  rules="required|email"
                  v-model="form.fields.email"
                  :errors="form.errors.email"
              />
              <TextAreaInput
                  required
                  label="message"
                  prepend-icon="mdi-comment"
                  rules="required"
                  v-model="form.fields.message"
                  :errors="form.errors.message"
              />

            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                  text
                  :disabled="invalid || !validated"
                  @click="handleSubmit(submit)"
              >
                {{ $t('label.submit') }}
              </v-btn>
            </v-card-actions>
        </v-form>
      </ValidationObserver>
  </v-card>
</template>
<script>
import {ValidationObserver} from 'vee-validate';
import TextInput from "~/views/layouts/forms/TextInput";
import TextAreaInput from "~/views/layouts/forms/TextAreaInput";
import upperFirst from 'lodash/upperFirst';

export default {
  components: {
    TextAreaInput,
    TextInput,
    ValidationObserver
  },
  data: () => ({
    form: {
      fields: {
        name: '',
        email: '',
        message: '',
      },
      errors: {
        name: null,
        email: null,
        message: null
      },
      messages: null,
      valid: null,
      success: null
    },
    maps: {
      zoom: 15,
      position: {
        lat: 51.930318,
        lng: 4.458284,
      }
    }
  }),
  methods: {
    /**
     * Validate and submit the form
     */
    submit() {
      this.$refs.ContactForm.validate().then(success => {
        if (success) {
          this.clearErrors();
          return this.$store.dispatch('contact/store', this.form.fields)
              .then(({meta}) => {
                this.clearForm();
                this.form.success = this.$t(`api['${meta.message}']`);
              })
              .catch(({errors, meta}) => {
                if (errors) {
                  if (meta) {
                    this.form.messages = [this.$t(`api['${meta.message}']`)];
                  }
                  Object.entries(errors).map(([label, error]) => {
                    this.form.errors[label] = error.map(msg => upperFirst(this.$t(`api['${msg}']`).toLowerCase()));
                  });
                }
              })
        }
      });
    },
    clearForm() {
      Object.keys(this.form.fields).map((key) => {
        this.form.fields[key] = '';
      });
      this.clearErrors();
    },
    clearErrors() {
      Object.keys(this.form.errors).map((key) => {
        this.form.errors[key] = null;
      });
      this.form.messages = null;
      this.form.valid = null;
      this.$refs.ContactForm.reset()
    }
  }
}
</script>